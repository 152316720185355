<template>
    <section id="content">
        <div class="content-wrap py-0">
            <div class="section m-0 border-top-0 skrollable skrollable-between">
                <div class="container-fluid center clearfix">

                    <div class="heading-block">
                        <h2>Change your business with the help of the cloud technologies</h2>
                        <span>Unleash the whole potential of the cloud computing and promote your business with our team of Crafty Tech, SRL</span>
                    </div>
                    <div class="container">

                        <div class="card card-body">
                            <div class="row col-mb-50 mb-0">
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-cloud i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Minimization of the IT management costs</h3>
                                            <p>Move your IT infrastructure to the Cloud with the help of our experts and save on the equipment and the maintenance.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center  fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-cloud-drizzle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Expand your service offerings</h3>
                                            <p>Using the advanced cloud technologies, you can significantly reduce the cost of your business servicing and the time of the product market entry.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-cloud-lightning i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Increase an operational performance</h3>
                                            <p>By installing a secure cloud system, you can create the conditions for collaboration between your employees, partners and the field-type agents.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-cloud-rain i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Consulting and Cloud Strategy</h3>
                                            <p>We will evaluate carefully the enterprise’s readiness for the cloud service and develop a detailed roadmap for the cloud implementation, in which we will list which type to use — public, private, hybrid.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-cloud1 i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Cloud safety and Management</h3>
                                            <p>Our cloud team will help your business to create the secure management methods, hacking tests and emergency recovery. We ensure the complete security of your cloud infrastructure by implementing the best processes and standards.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-cloudversify i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Clouds’ management</h3>
                                            <p>To ensure a high reliability, a continuous optimization and the management of your operations in the cloud, we offer the monitoring tools and services and its’ automation.</p>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-cloud2 i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Consulting and Cloud Strategy</h3>
                                            <p>We will evaluate carefully the enterprise’s readiness for the cloud service and develop a detailed roadmap for the cloud implementation, in which we will list which type to use — public, private, hybrid.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-cloudversify i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Cloud safety and Management</h3>
                                            <p>Our cloud team will help your business to create the secure management methods, hacking tests and emergency recovery. We ensure the complete security of your cloud infrastructure by implementing the best processes and standards.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-cloud-snow i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>Clouds’ management</h3>
                                            <p>Our cloud team will help your business to create the secure management methods, hacking tests and emergency recovery. We ensure the complete security of your cloud infrastructure by implementing the best processes and standards.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>

    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Cloud",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Cloud',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>

</style>